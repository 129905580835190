<template>
	<div class="opts_wrap" v-loading="loading">
		<el-form label-width="100px" :model="strategy" class="opts_line" v-if="strategy">
			
			<el-form-item label="选择策略" required>
				<el-select class="ml8" v-model="strategy.id" @change="bindSelect">
					<el-option :label="item.name" :value="item.id" v-for="(item,index) in list" :key="index" >{{item.name}}</el-option>
				</el-select>
			</el-form-item>
			<template v-if="!strategy.id">
				<el-form-item label="价格公式" required>
					<div class="con">
						<p class="t">原价格 x</p>
						<el-input class="w80 ml8" v-model="strategy.coefficient"></el-input>
						<el-select class="w80 ml8" v-model="strategy.type">
							<el-option label="+" :value="1">+</el-option>
							<el-option label="-" :value="2">-</el-option>
						</el-select>
						<el-input class="w80 ml8" v-model="strategy.money"></el-input>
						<p class="t ml8">元</p>
					</div>
				</el-form-item>
				<el-form-item label="分角设置" required>
					<div class="con">
						<el-radio-group v-model="strategy.set_fenjiao">
							<el-radio :label="item.type" v-for="(item,index) in strategy.fenjiao" :key="index">{{item.name}}</el-radio>
						</el-radio-group>
					</div>
				</el-form-item>
				<el-form-item label="铺货方式" required>
					<div class="con">
						<el-radio-group v-model="strategy.puhuo_type">
							<el-radio :label="1">上架</el-radio>
							<el-radio :label="2">放入仓库</el-radio>
							<el-radio :label="3">放入草稿箱</el-radio>
						</el-radio-group>
					</div>
				</el-form-item>
			</template>
		</el-form>
		<div class="btn_wrap">
			<p class="btn" @click="saveBtn()">提交</p>
			<p class="btn" @click="cancel()">取消</p>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapActions,mapState,mapMutations } from "vuex";
export default {
	name: "pricePloy",
	props: {
		isLoading: {
			type: Boolean,
			default: false
		},
		show: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			strategy: null,
			init_strategy: null,
			list:[],
			is_save:false,
			loading: false
		};
	},
	
	created(){
	},
	mounted() {
		this.init()
	},
	
	computed:{
		...mapState({
			
		}),
	},
	watch:{
		show(val) {
			if(val){
				this.init()
			}
		},
		isLoading(val){
			this.loading = val
		}
	},
	
	methods: {
		...mapActions({
			getCelveData:"ucenter/getCelveData",
			saveCelveData:"ucenter/saveCelveData",
		}),
		init(){//获取策略列表
			this.loading = true
			let param = {limit:100,page:1}
			Object.assign(param, this.searchData)
			this.getCelveData({data:param,success:(res)=>{
				if(res.code==200){
					let list = res.data.data
					console.log(this.list)
					this.init_strategy = res.data.init_strategy
					this.strategy = res.data.init_strategy
					var diy_strategy = res.data.init_strategy
					for(var i in list){
						if(list[i].is_default==1){
							this.strategy = {...list[i]}
						}
					}
					diy_strategy.id = 0
					diy_strategy.name = '自定义'
					list.push(diy_strategy)
					this.list = list
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
				this.loading = false
			}})
		},
		bindSelect(e){
			console.log(e)
			let item = this.list.find(val => val.id == e)
			this.strategy = {...item}
		},
		saveBtn(){
			let ployData = this.strategy
			this.loading = true
			this.$emit('submit',ployData)
		},
		
	
		cancel(){
			this.$emit('close', {})
		}
	},

}
</script>

<style scoped>
	.opts_wrap{padding-top: 14px;}
	
	
	.opts_line .txt {
			width: 120px;
			text-align: right;
			font-size: 14px;
			
		}
	
		.opts_line .con {
			width: 90%;
			display: flex;
			align-items: center;
			height: 40px;
		}
	
		.opts_line .con .t {
			font-size: 14px;
			white-space: nowrap;
			margin:0 10px;
		}
		
	.opts_wrap .btn_wrap{margin: 40px auto 0 auto;display: flex;align-items: center;justify-content: center;}
	.opts_wrap .btn_wrap .btn{width: 80px;height: 34px;border-radius: 4px;display: flex;justify-content: center;align-items: center;cursor: pointer;box-sizing: border-box;font-size: 14px;}
	.opts_wrap .btn_wrap .btn:nth-child(1){background: var(--red);color: #fff;margin-right: 14px;}
	.opts_wrap .btn_wrap .btn:nth-child(2){background: #fff;color: #333;border: 1px solid #ddd;margin-right: 14px;}
	.ml8{margin-left: 8px;}
	.opts_wrap .btn_wrap .btn:nth-child(3){background: #fff;color: #333;border: 1px solid #ddd;}
	.w80{width: 80px;}
	
	
	.el-input--suffix .el-input__inner{padding: 0 0 0 12px !important;font-size: 16px;}
	/*red border color*/
	.el-input__inner:focus ,.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus ,.el-radio__input.is-checked .el-radio__inner ,.el-radio__inner:hover{border-color: var(--red);}
	
	/*red color*/
	.el-select-dropdown__item.selected ,.el-radio__input.is-checked+.el-radio__label{color: var(--red);}
	
	/*red background color*/
	.el-radio__input.is-checked .el-radio__inner{background-color: var(--red);}
</style>
